// Generated by Framer (1d71865)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ilYgDrvtt"];

const serializationHash = "framer-SHJvV"

const variantClassNames = {ilYgDrvtt: "framer-v-11ajr1r"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ilYgDrvtt", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-11ajr1r", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ilYgDrvtt"} ref={ref ?? ref1} style={{backgroundColor: "rgb(56, 68, 56)", borderBottomLeftRadius: 200, borderBottomRightRadius: 200, borderTopLeftRadius: 200, borderTopRightRadius: 200, ...style}}><SVG className={"framer-7eheun"} data-framer-name={"Vector"} layout={"position"} layoutDependency={layoutDependency} layoutId={"gtBbYKE2S"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 12 9\"><path d=\"M 10.417 1.5 L 4 7.917 L 1.083 5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"rgb(255,255,255)\" stroke-linecap=\"square\" stroke-miterlimit=\"10\" stroke-dasharray=\"\"></path></svg>"} svgContentId={9798817991} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-SHJvV.framer-1wuruqq, .framer-SHJvV .framer-1wuruqq { display: block; }", ".framer-SHJvV.framer-11ajr1r { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 24px; height: 30px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 30px; }", ".framer-SHJvV .framer-7eheun { flex: none; height: 9px; position: relative; width: 12px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-SHJvV.framer-11ajr1r { gap: 0px; } .framer-SHJvV.framer-11ajr1r > * { margin: 0px; margin-left: calc(24px / 2); margin-right: calc(24px / 2); } .framer-SHJvV.framer-11ajr1r > :first-child { margin-left: 0px; } .framer-SHJvV.framer-11ajr1r > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 30
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameraL3s0NnbA: React.ComponentType<Props> = withCSS(Component, css, "framer-SHJvV") as typeof Component;
export default FrameraL3s0NnbA;

FrameraL3s0NnbA.displayName = "Table Row/Tick";

FrameraL3s0NnbA.defaultProps = {height: 30, width: 30};

addFonts(FrameraL3s0NnbA, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})